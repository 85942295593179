
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { useRoute } from "vue-router";
import { ref, computed, watch } from "vue";
import axios from "axios";

export default {
  name: "Folder",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
  },
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || "Notiziario");
    const matchedFolder = computed(() => route.params.id);
    const content = ref("");
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
    axios
      .get(
        "https://parrocchianoventa.org/Notiziario-parrocchiale/notiziario-parrocchiale.html?tmpl=component"
      )
      .then((response: any) => {
        //let self = this;
        const parser = new DOMParser();
        const doc = parser.parseFromString(response.data, "text/html");
        const s = doc.querySelector("div#system>div.item>div.content")
          ?.innerHTML;
        if (s != undefined)
          content.value = s.replaceAll(
            '="/',
            '="https://parrocchianoventa.org/'
          );
        window.console.log("Loaded...");
      });
    return { folder, content };
  },
};
